import i18n from '@/i18n'
import reminderApi from '@/api/reminder.api'
import SelectorTree from '@/components/commons/selector/dialog/tree/SelectorTree.vue'

export default {
  name: 'PlanDialogComponent',
  components: {
    SelectorTree
  },
  props: {
    showDialog: Boolean,
    edit: Boolean,
    plan: Object,
    enterprise: String
  },
  data: () => ({
    validForm: false,
    validationRules: {
      required: (value) => !!value || `${i18n.t('required')}`,
      expirationRequired: (value) => (value.expiration.hours.applies || value.expiration.km.applies || value.expiration.date.applies) ||
      `${i18n.t('reminder.type.expirationRequired')}`,
      noNegative: (value, parent) => !parent || !value || parseFloat(value) >= 0 || `${i18n.t('maxValue', { max: 0 })}`
    },
    typeSelectorModel: {
      normalizer: (node) => {
        return {
          id: node._id,
          label: node.name,
          children: node.children
        }
      },
      data: null,
      selected: null,
      show: true,
      singleSelect: false,
      selectAll: false
    }
  }),
  computed: {
  },
  created () {
    this.loadSelector()
  },
  methods: {
    cancel () {
      this.$emit('closeDialog')
      this.resetFields()
      this.$refs.form.resetValidation()
    },
    save () {
      this.plan.reminderTypeIds = this.typeSelectorModel.selected
      this.$emit('saveDialog', this.plan)
      this.resetFields()
      this.$refs.form.resetValidation()
    },
    async loadSelector () {
      this.typeSelectorModel.selected = this.plan.reminderTypeIds || null
      this.typeSelectorModel.selectAll = false
      this.typeSelectorModel.data = (await reminderApi.getAllTypes(this.enterprise)).data.filter(t => t.visible !== false)
    },
    resetFields () {
      this.typeSelectorModel.selected = null
      this.typeSelectorModel.selectAll = false
    }
  },
  watch: {
    plan () {
      this.loadSelector()
    }
  }
}
